import React, { useState } from 'react';
import { defectAPI } from '../../../api';
import WplInput from '../../../components/wpl-input/WplInput';
import WplButton from '../../../components/wpl-button/WplButton';
import AlertPopup from '../../../components/alert-popup/AlertPopup';
import TurbineAnimation from '../../../components/turbine-animation/TurbineAnimation';
import './edit-wd-defects.css';

export default function EditWDDefects() {
    const [reportDefectID, setReportDefectID] = useState('');
    const [defectData, setDefectData] = useState([]);
    const [editableDefect, setEditableDefect] = useState({});
    const [loading, setLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertData, setAlertData] = useState({ status: '', })

    const fetchDefect = async () => {
        setLoading(true)
        try {
            const defect = await defectAPI.listDefectByReportID(reportDefectID);
            setLoading(false)
            setDefectData(defect);
            setEditableDefect(defect)
        } catch (error) {
            setLoading(false)
            console.log('Error fetching defect:', error);

        }
    };

    const handleInputChange = (uniqueDefectId, field, value) => {
        setEditableDefect((prev) => ({
            ...prev,
            [uniqueDefectId]: {
                ...prev[uniqueDefectId],
                [field]: value,  
            },
        }));
    };
    
    const updateDefect = async (uniqueDefectId) => {
        setLoading(true)

        const defect = editableDefect[uniqueDefectId] || {};  
        const { distanceFromHub = "", severity, defectType, surface, repairRecommendation, repairComment } = defect;
    
        let parsedDistance = null;
        if (String(distanceFromHub).trim() !== "") {
            parsedDistance = parseFloat(String(distanceFromHub).replace(",", "."));
            if (isNaN(parsedDistance)) parsedDistance = null;
        }
    
        const payload = {
            distance: parsedDistance,
            severity,
            defect_type: defectType,
            surface,
            repair_action: repairRecommendation,
            repair_comment: repairComment,
        };

        try {
            await defectAPI.updateDefectAdmin(uniqueDefectId, payload);
            setLoading(false)
            setShowAlert(true)
            setAlertData({ status: 'success', title: 'Defect updated successfully!' })
        } catch (error) {
            setLoading(false)
            console.log('Error updating defect:', error.response || error);
            setShowAlert(true)
            setAlertData({ status: "wrong", title: "Something went wrong..." })
        }
    };

    return (
        <div className="edit-wd-defects-container">
            {loading && <TurbineAnimation />}
            {showAlert && <AlertPopup
                showPopup={showAlert}
                closePopup={setShowAlert}
                status={alertData.status}
                title={alertData.title}
                buttonText={"Close"}
            >
            </AlertPopup>}
            <div className="defect-input-wrapper">
                <WplInput
                    value={reportDefectID}
                    placeholder={'Enter defect ID...'}
                    onChanged={(v) => setReportDefectID(v)}
                />
                <WplButton value="Fetch Defect" onClick={fetchDefect} />
            </div>

            {defectData.length > 0 && <div>
                <table className="edit-wd-defects-table">
                    <thead>
                        <tr>
                            <th>Defect ID</th>
                            <th>Turbine</th>
                            <th>Windfarm</th>
                            <th>Blade</th>
                            <th>Dist from hub (m)</th>
                            <th>Severity</th>
                            <th>Defect type</th>
                            <th>Surface</th>
                            <th>Repair action</th>
                            <th>Repair comment</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        {defectData.map((d) => (
                            <tr key={d.id}>
                                <td>{d.reportDefectId}</td>
                                <td>{d.turbineName}</td>
                                <td>{d.windfarm}</td>
                                <td>{d.blade}</td>
                                <td>
                                    <input
                                        type="text"
                                        value={editableDefect[d.id]?.distanceFromHub ?? d.distanceFromHub ?? ""}
                                        onChange={(e) => handleInputChange(d.id, 'distanceFromHub', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <div className={`severity-select severity-level-${editableDefect?.id === d.id ? editableDefect.severity : d.severity}`}>
                                        <select
                                            value={editableDefect[d.id]?.severity || d.severity}
                                            onChange={(e) => handleInputChange(d.id,'severity', parseInt(e.target.value))}
                                            className={`severity-level-${editableDefect[d.id]?.severity || d.severity}`}
                                        >
                                            {[1, 2, 3, 4, 5].map((level) => (
                                                <option key={level} value={level} className={`severity-level-${level}`} >
                                                    {level}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={editableDefect[d.id]?.defectType ?? d.defectType ?? ""} 
                                        onChange={(e) => handleInputChange(d.id,'defectType', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={editableDefect[d.id]?.surface ?? d.surface ?? ""}
                                        onChange={(e) => handleInputChange(d.id,'surface', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={editableDefect[d.id]?.repairRecommendation ?? d.repairRecommendation ?? ""}
                                        onChange={(e) => handleInputChange(d.id,'repairRecommendation', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <textarea
                                        className='repair-comment'
                                        value={editableDefect[d.id]?.repairComment ?? d.repairComment ?? ""}
                                        onChange={(e) => handleInputChange(d.id,'repairComment', e.target.value)}
                                    />
                                </td>
                                <td>
                                {editableDefect[d.id] && (
                                        <WplButton value="Save" onClick={() => updateDefect(d.id)}/>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
        </div>
    );
}
