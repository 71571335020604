import React, {useState} from 'react'
import WplInput from "../../../components/wpl-input/WplInput";
import WplButton from "../../../components/wpl-button/WplButton";
import DataCoverageTimeline from "../data-coverage-timeline/DataCoverageTimeline";
import './lassie-region-process-component.css';
import {lassieTaskmasterAPI} from "../../../api";

export default function LassieRegionProcessComponent({query_coverage, process_coverage, region_id, setClickedCoverage}) {
    const [fromTimestamp, setFromTimestamp] = useState('');
    const [toTimestamp, setToTimestamp] = useState('');

    return (<div className='lassie-region-process-component'>
        <label>Query</label>
        <DataCoverageTimeline coverage={query_coverage} onClickCoverage={c => {
            console.log(c)
            setClickedCoverage(({
                covered: c.covered,
                from_timestamp: new Date(c.from_timestamp),
                to_timestamp: new Date(c.to_timestamp)
            }))
            if (c.covered) return;
            setFromTimestamp(c.from_timestamp)
            setToTimestamp(c.to_timestamp)
        }}/>
        <label>Processed</label>
        <DataCoverageTimeline coverage={process_coverage} onClickCoverage={c => {
            setClickedCoverage(({
                covered: c.covered,
                from_timestamp: new Date(c.from_timestamp),
                to_timestamp: new Date(c.to_timestamp)
            }))
            console.log(c)
            if (c.covered) return;
            setFromTimestamp(c.from_timestamp)
            setToTimestamp(c.to_timestamp)
        }}/>
        <h2>Process</h2>
        <WplInput type='text' title='from' value={fromTimestamp} onChanged={setFromTimestamp}/>
        <WplInput type='text' title='to' value={toTimestamp} onChanged={setToTimestamp}/>
        <WplButton value='Start' big disabled={!fromTimestamp || !toTimestamp} onClick={_ => {
            lassieTaskmasterAPI.run_on_historic_period(region_id, fromTimestamp, toTimestamp)
        }}/>
    </div>)
}
