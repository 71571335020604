import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../auth/user-service";
import { userAPI } from "../../api";
import WplButton from "../../components/wpl-button/WplButton";
import { useKeyboardShortcut } from "../../components/UseKeyboardShortcut";
import TurbineAnimation from "../../components/turbine-animation/TurbineAnimation";
import AlertPopup from "../../components/alert-popup/AlertPopup";
import logo from "../../assets/img/WPL-Global-Blade-Experts-04.png";
import ShowIcon from "../../assets/eye.png";
import HideIcon from "../../assets/closed-eye.png";
import "./login.css";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    title: "",
    status: "",
    buttonText: "",
  });

  const navigate = useNavigate();

  const doLogin = useCallback(() => {
    setLoading(true);
    userService
      .login(email, password)
      .then(() => {
        setLoading(false);
        const redir_path = localStorage.getItem("redirAfterLogin");
        navigate(redir_path && redir_path !== "/login" ? redir_path : "/");
      })
      .catch(() => {
        setLoading(false);
        setAlert({
          show: true,
          message: "Wrong password or username",
          title: "Something went wrong...",
          status: "wrong",
          buttonText: "Go back",
        });
      });
  }, [email, password, navigate]);

  const createPasswordReset = useCallback(() => {
    setLoading(true);
    userAPI
      .requestPasswordReset(email)
      .then(() => {
        setLoading(false);
        setResetPassword(false);
        setAlert({
          show: true,
          message: "Please check your inbox for further instructions.",
          title: "Email sent!",
          status: "success",
          buttonText: "Go back",
        });
      })
      .catch(() => {
        setLoading(false);
        setAlert({
          show: true,
          message: "No account found with that email.",
          title: "Something went wrong...",
          status: "wrong",
          buttonText: "Go back",
        });
      });
  }, [email]);

  const handleResetPasswordToggle = useCallback(() => {
    setResetPassword(!resetPassword);
    setEmail("");
    setPassword("");
  }, [resetPassword]);

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };




  useKeyboardShortcut(["Enter"], doLogin);

  return (
    <main className="login-page">
      <div className="bg-img" />
      <div className={`overlay ${loading ? "loading" : ""}`}>
        <TurbineAnimation overlayClassname="darker-overlay" />
      </div>
      <div className="login-wrapper">
        <a href="https://windpowerlab.com/" target="_blank" rel="noopener noreferrer">
          <img alt="Wind Power Lab" src={logo} className="login-logo" />
        </a>
        {!resetPassword &&
          <form autoComplete="on" className="login-form">
            <div className="input-container">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="username"
                required
              />
            </div>
            <div className="input-container password-container">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setShowPassword(!showPassword)}
              >
                <img
                  src={showPassword ? ShowIcon : HideIcon}
                  alt="Toggle Password Visibility"
                />
              </button>
            </div>
            {email && password && !isValidEmail(email) && <p className="error">Please enter a valid email address.</p>}
            <WplButton value="Log in"
              loading={loading}
              onClick={doLogin}
              className={`login-btn ${!(email && isValidEmail(email) && password) ? "disabled" : ""}`}
              big />
          </form>
        }

        {resetPassword &&
          <form autoComplete="on" className="login-form">
            <div className="input-container">
              <label htmlFor="email">Enter your email to receive a password reset link</label>
              <input
                id="email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="username"
                required
              />
            </div>
            <input type="text" name="username" value={email} hidden readOnly />
            {email && !isValidEmail(email) && <p className="error">Please enter a valid email address.</p>}

            <WplButton value="Reset Password"
              onClick={createPasswordReset}
              className={`login-btn ${!(email && isValidEmail(email)) ? "disabled" : ""}`}
              big />
          </form>
        }

        <div className="forgot-pw-container">
          <button onClick={handleResetPasswordToggle} className="forgot-pw-btn">
            {!resetPassword ? "Forgot Password?" : "Log in"}
          </button>
        </div>

      </div>

      <AlertPopup
        showPopup={alert.show}
        closePopup={() => setAlert({ ...alert, show: false })}
        status={alert.status}
        title={alert.title}
        buttonText={alert.buttonText}
      >
        <p>{alert.message}</p>
      </AlertPopup>

    </main>
  );
}
