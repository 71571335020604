import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { internalVideoProcessingAPI, reportAPI } from "../../../api";
import { heavyInternalVideoProcessingAPI } from "../../../heavy_duty_api";
import debounce from "lodash.debounce";
import WplInput from "../../../components/wpl-input/WplInput";
import WplButton from "../../../components/wpl-button/WplButton";
import './internal-video-dm.css'

const StatusEnum = {
    missingData: 1,
    ready: 2,
    done: 3
};

export default function InternalVideoDm() {
    const [availableReports, setAvailableReports] = useState([]);

    const [bucketPath, setBucketPath] = useState('');
    const [_fetchedOnBucketPath, setFetchedOnBucketPath] = useState(null);
    const [loading, setLoading] = useState(false);

    const [internalInspectionList, setInternalInspectionList] = useState([]);
    const [inspectionsWithStatus, setInspectionsWithStatus] = useState([]);

    const fetchList = useCallback((bucketPath) => {
        console.log('FETCHING', bucketPath)
        setLoading(true);
        internalVideoProcessingAPI
            .recursivelySearchForInspection(bucketPath)
            .then(internalInspections => {
                setInternalInspectionList(internalInspections);
                setFetchedOnBucketPath(bucketPath);
                setLoading(false);
            })
            .catch(v => {
                setLoading(false);
            });
    }, []);

    // Create status for each inspection
    useEffect(() => {
        setInspectionsWithStatus(internalInspectionList.map(ii => {
            const movieReady = ii.movie_bucket_paths.length === 1 || ii.stitched_bucket_path
            return {
                ...ii,
                stitched_status: movieReady ? StatusEnum.done : (ii.movie_bucket_paths.length > 1 ? StatusEnum.ready : StatusEnum.missingData),
                parsed_log_status: ii.parsed_log_bucket_path ? StatusEnum.done : StatusEnum.ready,
                stabilization_status: ii.stabilization_bucket_path ? StatusEnum.done : (movieReady ? StatusEnum.ready : StatusEnum.missingData),
                trajectory_status: ii.trajectory_bucket_path ? StatusEnum.done : (ii.log_bucket_path && ii.thm_bucket_path && ii.movie_bucket_paths.length > 0 ? StatusEnum.ready : StatusEnum.missingData)
            }
        }));
    }, [internalInspectionList]);

    // Initial useEffect
    useEffect(() => {
        reportAPI.listReports().then(setAvailableReports);
        setBucketPath('wpl-data-imports/weh')
        debounceFetchList('wpl-data-imports/weh');
    }, [fetchList]);

    const debounceFetchList = useMemo(() => debounce(fetchList, 800), [fetchList]);

    const handleStitching = async (inspectionValues) => {
        const updatedInspection = await heavyInternalVideoProcessingAPI.stitchMovie(inspectionValues);

        setInternalInspectionList(prev =>
            prev.map(inspection =>
                inspection.inspection_id === inspectionValues.inspection_id
                    ? { ...inspection, stitched_bucket_path: updatedInspection.stitched_bucket_path }
                    : inspection
            )
        );

        debounceFetchList(bucketPath);
    };

    const renderButtonFromStatus = useCallback((inspectionValues, status, readyText, onClick) => {
        const inspectionId = inspectionValues.inspection_id;
        if (status === StatusEnum.done) return <label>Done</label>;

        const relatedInspection = internalInspectionList.find(ii => ii.inspection_id === inspectionId);

        return (
            <WplButton
                value={readyText}
                disabled={status === StatusEnum.missingData}
                onClick={async () => {
                    const newValues = await onClick(relatedInspection);
                    setInternalInspectionList(prev =>
                        prev.map(p => p.inspection_id === inspectionId ? { ...p, ...newValues } : p)
                    );
                }}
            />
        );
    }, [internalInspectionList]);

    return (<div className='internal-video-dm'>
        <div className='horizontal'>
            <WplInput value={bucketPath} onChanged={v => {
                setBucketPath(v)
                debounceFetchList(v);
            }} />
            <WplButton value='refresh' onClick={_ => { debounceFetchList(bucketPath); }} />
        </div>

        {_fetchedOnBucketPath === bucketPath && <table className='internal-video-table'>
            <thead>
                <tr>
                    <th>Inspection ID</th>
                    <th>LOG</th>
                    <th>THM</th>
                    <th>Uniset</th>
                    <th>Movies</th>
                    <th></th>
                    <th>Stitched Movie</th>
                    <th>Parsed Log</th>
                    <th>Stabilizations</th>
                    <th>Trajectory</th>
                </tr>
            </thead>
            <tbody>
                {inspectionsWithStatus.map(tr => <tr key={tr.inspection_id}>
                    <td className='cursor-pointer' title={tr.root_bucket_path} onClick={_ => console.log(tr)}>{tr.inspection_id}</td>
                    <td>{tr.log_bucket_path ? 'Y' : ''}</td>
                    <td>{tr.thm_bucket_path ? 'Y' : ''}</td>
                    <td>{tr.uniset_bucket_path ? 'Y' : ''}</td>
                    <td>{tr.movie_bucket_paths.length}</td>
                    <td></td>
                    <td>{renderButtonFromStatus(tr, tr.stitched_status, 'Stitch', handleStitching)}</td>
                    <td>{renderButtonFromStatus(tr, tr.parsed_log_status, 'Parse log', internalVideoProcessingAPI.parseLog)}</td>
                    <td>{renderButtonFromStatus(tr, tr.stabilization_status, 'Stabilize video', heavyInternalVideoProcessingAPI.generateStabilization)}</td>
                    <td>{renderButtonFromStatus(tr, tr.trajectory_status, 'Generate', internalVideoProcessingAPI.createTrajectory)}</td>
                </tr>)}
            </tbody>
        </table>}
        {loading && <p>Loading...</p>}
    </div>)
}
