import React, { useCallback, useEffect, useMemo, useState } from "react";
import createSourceFromFeatures from "./createEllipseSourceFromFeatures";
import MapSettings from "../map-settings/MapSettings";
import MapReferences from "../map-references/MapReferences";
import MapPopup from "../map-popup/MapPopup";
import useOlMap from "./useOlMap";
import MapQa from "../map-qa/MapQa";
import { getIconForTurbine } from "./icons";
import { Point, Polygon } from "ol/geom";
import { Feature } from "ol";
import { fromLonLat } from "ol/proj";
import { Fill, Stroke, Style } from "ol/style";
import { Heatmap } from "ol/layer";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import "./ol-map.css";

const tileLayerFromUrl = (url) =>
  new TileLayer({
    source: new XYZ({
      url,
    }),
  });

export default function OlMap({
  turbines,
  lightning_strikes,
  forceShiftkeyToZoom = false,
}) {
  const [popoutSettingsOpen, setPopoutSettingsOpen] = useState(false);
  const [popoutReferencesOpen, setPopoutReferencesOpen] = useState(false);
  const [qaOpen, setQaOpen] = useState({ status: false, type: "" });
  const [showLightningTable, setShowLightningTable] = useState(true);
  const [showSensorTable, setShowSensorTable] = useState(true);

  const [layers, setLayers] = useState({});

  const [selectedTurbine, setSelectedTurbine] = useState(null);
  const [layerSettings, setLayerSettings] = useState({
    background: "topographic",
    turbines: true,
    lightning: false,
    collection_area: false,
    heatmap: false,
    turbine_specific: [],
    most_probable: false,
    only_show_outside_iec: false,
  });

  const {
    initialized,
    map,
    mapElement,
    setInitialSourceRender,
    turbineStyling,
  } = useOlMap();

  // turbine selection handling
  const [highlighted, setHighlighted] = useState(null);
  const [hovering, setHovering] = useState(null);

  // display 'hold shift to zoom' popup
  const [showHoldShiftToZoomPopup, setShowHoldShiftToZoomPopup] =
    useState(false);

  useEffect(() => {
    if (showHoldShiftToZoomPopup) {
      setTimeout(() => {
        setShowHoldShiftToZoomPopup(false);
      }, 5000);
    }
  }, [showHoldShiftToZoomPopup]);

  const handleOnPointerMove = useCallback(
    (e) => {
      let newHover = null;
      map.forEachFeatureAtPixel(e.pixel, function (f) {
        if (!f.get("turbine_id")) return;
        if (newHover) return;
        newHover = f;
      });
      if (newHover === hovering) return;

      setHovering((previousHover) => {
        if (newHover) {
          if (
            !selectedTurbine ||
            newHover.get("turbine_id") !== selectedTurbine.id
          ) {
            newHover.setStyle(turbineStyling(newHover, map).hover);
          }
        }
        if (previousHover) {
          if (
            !selectedTurbine ||
            previousHover.get("turbine_id") !== selectedTurbine.id
          ) {
            previousHover.setStyle(turbineStyling(previousHover, map).normal);
          }
        }
        return newHover;
      });
    },
    [hovering, turbineStyling, selectedTurbine, map]
  );

  const handleOnClick = useCallback(
    (e) => {
      setPopoutSettingsOpen(false);
      setPopoutReferencesOpen(false);
      if (highlighted !== null) {
        highlighted.setStyle(turbineStyling(highlighted, map).normal);
        setHighlighted(null);
      }

      let newlySelected = null;
      map.forEachFeatureAtPixel(e.pixel, function (f) {
        if (highlighted === f) return;

        const turbineId = f.get("turbine_id");
        if (!turbineId) return;
        newlySelected = f;

        newlySelected.setStyle(turbineStyling(newlySelected, map).selected);
        setHighlighted(newlySelected);
        return true;
      });

      if (newlySelected) {
        const turbineId = newlySelected.get("turbine_id");
        if (!turbineId) return;
        setSelectedTurbine(turbines.find((t) => t.id === turbineId));
      } else {
        setSelectedTurbine(null);
      }
    },
    [highlighted, turbineStyling, map, turbines]
  );

  // if only one turbine, show lightning map
  useEffect(() => {
    if (!initialized) return;
    setLayerSettings((prev) => ({
      ...prev,
      lightning: turbines.length === 1,
    }));
  }, [turbines, initialized]);

  // set up and destroy mouse callbacks
  useEffect(() => {
    if (!initialized) return;

    const w = (evt) => {
      const { deltaY, ctrlKey } = evt.originalEvent;
      if (forceShiftkeyToZoom && !ctrlKey) {
        setShowHoldShiftToZoomPopup(true);
        evt.stopPropagation();
        return;
      }

      setShowHoldShiftToZoomPopup(false);

      if (deltaY !== 0) {
        evt.preventDefault();
      }
    };

    map.on("wheel", w);
    map.on("click", handleOnClick);
    map.on("pointermove", handleOnPointerMove);

    return () => {
      map.un("wheel", w);
      map.un("click", handleOnClick);
      map.un("pointermove", handleOnPointerMove);
    };
  }, [initialized, map, handleOnClick, handleOnPointerMove]);

  const turbineSource = useMemo(() => {
    if (!turbines || turbines.length === 0) return null;
    const turbineFeatures = turbines.map((t) => {
      const url = getIconForTurbine(t, layerSettings.most_probable);
      const f = new Feature({
        geometry: new Point(fromLonLat([t.lon, t.lat], "EPSG:3857")),
        name: t.name,
        turbine_id: t.id,
        status_icon: url,
        canHover: true,
      });
      f.setStyle(turbineStyling(f, map).normal);

      return f;
    });
    return new VectorSource({
      features: turbineFeatures,
    });
  }, [turbines, turbineStyling, layerSettings, map]);

  const collectionAreaSource = useMemo(() => {
    if (!turbines || turbines.length === 0) return null;
    const collectionAreaFeatures = turbines.map((t) => {
      const f = new Feature({
        geometry: new Polygon([
          t.collection_area.map((c) => fromLonLat([c.lon, c.lat], "EPSG:3857")),
        ]),
      });
      f.setStyle(
        new Style({
          fill: new Fill({
            color: "rgba(115,142,218, 1)", // sets the opacity on the entire layer instead of individually, to easily dissolve
          }),
        })
      );
      return f;
    });
    return new VectorSource({
      features: collectionAreaFeatures,
    });
  }, [turbines, turbineStyling, layerSettings, map]);

  // set source for first render (turbineSource)
  useEffect(() => {
    if (!turbineSource) return;
    setInitialSourceRender(turbineSource);
  }, [turbineSource]);

  const lightningGroups = useMemo(() => {
    if (lightning_strikes === null) return null;

    const lightningGroups = {};
    const allLightningOut = [];
    const allLightningMostProb = [];
    const allLightningStrikes = new Map();

    turbines.forEach((turbine) => {
      const allTurbineStrikes = [];
      const outsideIec = [];
      const turbineMostProbable = [];
      const turbineMostProbableOutsideIec = [];
      lightning_strikes
        .filter((tl) => tl.turbine_id === turbine.id)
        .map((tl) => {
          const f = {
            type: "Feature",
            properties: tl.lightning_id,
            geometry: {
              type: "Polygon",
              coordinates: [
                tl.confidence_ellipse.map((c) =>
                  fromLonLat([c.lon, c.lat], "EPSG:3857")
                ),
              ],
            },
          };

          if (!allLightningStrikes.has(tl.lightning_id)) {
            allLightningStrikes.set(tl.lightning_id, f);
          }

          if (tl.most_probable) {
            if (tl.outside_certification) {
              allLightningOut.push(f);
            }
            // since this layer shows all lightning - we can use most_probable to only select one turbine_lightning for each lightning
            allLightningMostProb.push(f);
            turbineMostProbable.push(f);

            if (tl.outside_certification) {
              turbineMostProbableOutsideIec.push(f);
            }
          }

          if (tl.outside_certification) {
            outsideIec.push(f);
          }
          allTurbineStrikes.push(f);
        });

      lightningGroups[turbine.id] = {
        all: createSourceFromFeatures(allTurbineStrikes),
        outside: createSourceFromFeatures(outsideIec),
        most_probable: createSourceFromFeatures(turbineMostProbable),
        most_probable_outside_iec: createSourceFromFeatures(
          turbineMostProbableOutsideIec
        ),
      };
    });

    const uniqueAllLightningStrikes = Array.from(allLightningStrikes.values());

    lightningGroups["unified"] = {
      all: createSourceFromFeatures(uniqueAllLightningStrikes),
      outside: createSourceFromFeatures(allLightningOut),
      most_probable: createSourceFromFeatures(allLightningMostProb),
      most_probable_outside_iec: createSourceFromFeatures(allLightningOut),
    };

    return lightningGroups;
  }, [turbines, lightning_strikes]);


  useEffect(() => {
    const res = {};
    turbines.forEach((turbine) => {
      res[turbine.id] = new VectorLayer({
        source: createSourceFromFeatures([
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: [
                turbine.collection_area.map((c) =>
                  fromLonLat([c.lon, c.lat], "EPSG:3857")
                ),
              ],
            },
          },
        ]),
        style: [
          new Style({
            stroke: new Stroke({
              color: "black",
              width: 1,
            }),
            fill: new Fill({
              color: "rgba(115,142,218, 0.3)",
            }),
          }),
        ],
      });
    });
    setLayers((prev) => ({
      ...prev,
      turbine_collection_area: res,
    }));
  }, [turbines]);

  // update lightning layers if lightning groups change
  useEffect(() => {
    if (!lightningGroups) return;

    const turbineLightningLayers = {};
    Object.keys(lightningGroups).forEach((turbine_id) => {
      const {
        outside,
        all,
        most_probable,
        most_probable_outside_iec,
        collection_area,
      } = lightningGroups[turbine_id];
      if (layerSettings.only_show_outside_iec) {
        // if (outside.length === 0) return;
      }

      let relevant = null;
      if (layerSettings.most_probable) {
        if (layerSettings.only_show_outside_iec) {
          relevant = most_probable_outside_iec;
        } else {
          relevant = most_probable;
        }
      } else {
        if (layerSettings.only_show_outside_iec) {
          relevant = outside;
        } else {
          relevant = all;
        }
      }

      turbineLightningLayers[turbine_id] = new VectorLayer({
        source: relevant,
        style: [
          new Style({
            stroke: new Stroke({
              color: "black",
              width: 1,
            }),
            fill: new Fill({
              color: "rgba(255, 0, 0, 0.2)",
            }),
          }),
        ],
      });
    });
    setLayers((prev) => ({
      ...prev,
      lightning: turbineLightningLayers,
    }));
  }, [lightningGroups, layerSettings]);

  const heatmapLayerSource = useMemo(() => {
    if (!lightning_strikes) return null;

    const lightningFeatures = lightning_strikes.map(
      (t) =>
        new Feature({
          geometry: new Point(fromLonLat([t.lon, t.lat], "EPSG:3857")),
          peak_current: t.peak_current,
        })
    );

    return new VectorSource({
      features: lightningFeatures,
    });
  }, [lightning_strikes]);

  // set layers
  useEffect(() => {
    if (!heatmapLayerSource || !turbineSource) return;

    const initTurbineLayer = new VectorLayer({
      source: turbineSource,
    });

    const heatmapLayer = new Heatmap({
      source: heatmapLayerSource,
      blur: 25,
      radius: 15,
      weight: function (feature) {
        let peak_current = feature.get("peak_current");
        peak_current = parseFloat(peak_current);
        // Normalize value (values exceeding 1 are clamped to 1 by OpenLayers)
        return Math.abs(peak_current) / 100;
      },
    });

    setLayers((prev) => ({
      ...prev,
      turbines: initTurbineLayer,
      heatmap: heatmapLayer,
      backgroundImage: tileLayerFromUrl(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      ),
      backgroundTopo: tileLayerFromUrl(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
      ),
      backgroundElevation: tileLayerFromUrl(
        'https://server.arcgisonline.com/ArcGIS/rest/services/Elevation/World_Hillshade/MapServer/tile/{z}/{y}/{x}'
      ),
      collection_area: new VectorLayer({
        source: collectionAreaSource,
        opacity: 0.3,
      }),
    }));
  }, [heatmapLayerSource, turbineSource, collectionAreaSource]);

  // Update turbine colors based on selected turbine legend

  useEffect(() => {
    if (!initialized) return;
    const layers = map.getLayers();
    if (!layers) return;
    const newTurbineLayer = new VectorLayer({
      source: turbineSource,
    });
    setLayers((prev) => ({ ...prev, turbines: newTurbineLayer }));
  }, [map, initialized, turbineSource]);

  // Render layers from settings

  useEffect(() => {
    if (!initialized || !layers.lightning) return;

    const selectedLayers = [];

    if (layerSettings.background === "topographic") {
      selectedLayers.push(layers.backgroundTopo);
    } else if (layerSettings.background === "elevation") {
      selectedLayers.push(layers.backgroundElevation);
    } else {
      selectedLayers.push(layers.backgroundImage);

    }

    if (layerSettings.collection_area) {
      selectedLayers.push(layers.collection_area);
    }

    if (selectedTurbine) {
      selectedLayers.push(layers.turbine_collection_area[selectedTurbine.id]);
      selectedLayers.push(layers.lightning[selectedTurbine.id]);
    } else {
      if (layerSettings.lightning) {
        selectedLayers.push(layers.lightning.unified);
      }
    }

    if (layerSettings.heatmap) {
      selectedLayers.push(layers.heatmap);
    }

    if (layerSettings.turbines) {
      selectedLayers.push(layers.turbines);
    }

    map.setLayers(selectedLayers);
  }, [map, layerSettings, initialized, layers, selectedTurbine]);

  return (
    <div className="ol-map-parent">
      {qaOpen.status && qaOpen.type !== "" && (
        <MapQa qaOpen={qaOpen} setQaOpen={setQaOpen} />
      )}

      <div
        ref={mapElement}
        key="main-wf-map"
        className="ol-map-container"
      ></div>

      <MapPopup
        selectedTurbine={selectedTurbine}
        layerSettings={layerSettings}
        showLightningTable={showLightningTable}
        setShowLightningTable={setShowLightningTable}
        showSensorTable={showSensorTable}
        setShowSensorTable={setShowSensorTable}
      />

      {initialized && (
        <MapReferences
          popoutReferencesOpen={popoutReferencesOpen}
          setPopoutReferencesOpen={setPopoutReferencesOpen}
          setQaOpen={setQaOpen}
          setPopoutSettingsOpen={setPopoutSettingsOpen}
          popoutSettingsOpen={popoutSettingsOpen}
        />
      )}

      {initialized && (
        <MapSettings
          layerSettings={layerSettings}
          setLayerSettings={setLayerSettings}
          popoutSettingsOpen={popoutSettingsOpen}
          setPopoutSettingsOpen={setPopoutSettingsOpen}
          setQaOpen={setQaOpen}
          setPopoutReferencesOpen={setPopoutReferencesOpen}
          popoutReferencesOpen={popoutReferencesOpen}
        />
      )}

      {showHoldShiftToZoomPopup && (
        <div className="hold-shift-to-zoom-popup">
          <p>Hold control to zoom</p>
        </div>
      )}
    </div>
  );
}
