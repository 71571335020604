import React, { useState } from "react";
import "./arrow-animation.css";

export default function ArrowAnimation({ showMore, setShowMore, children = null, size = 1 }) {
  const [localShowMore, setLocalShowMore] = useState(false);
  const isOpen = showMore ?? localShowMore;

  const toggleShowMore = () => {
    if (setShowMore) {
      setShowMore(!isOpen);
    } else {
      setLocalShowMore(!isOpen);
    }
  };

  return (
    <>
      {isOpen && children}
      <div className="filters-arrow-icon-wrapper" onClick={toggleShowMore} style={{ transform: `scale(${size})` }}>
        <div className={`filters-arrow-icon ${isOpen ? "open" : "closed"}`}></div>
      </div>
    </>
  );
}
