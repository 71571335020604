import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LassieContext } from "../../../context/LassieProvider";
import WplButton from "../../../components/wpl-button/WplButton";
import WplInput from "../../../components/wpl-input/WplInput";
import TurbineAnimation from "../../../components/turbine-animation/TurbineAnimation";
import DashboardCard from "./DashboardCard";
import ComposedChartComponent from "./ComposedChartComponent";
import PieChartComponent from "./PieChartComponent";
import { extractUniqueYears, processDataForCharts } from "./utils";
import { useSyncSelectedWindfarm } from "../../../hooks/useSyncSelectedWindfarm ";
import { toPrettyDateStr } from "../../../prettyDate";
import windfarmIcon from "../../../assets/img/windfarm.png";
import "./dashboard.css";

export default function DashboardPage() {
  const navigate = useNavigate();
  const {
    windfarms,
    fetchStatisticsData,
    scenarioStatistics,
    windfarmStatistics,
    seasonalStatistics,
    fetchSeasonalStatistics,
    fetchTurbineStatistics,
    turbineStatistics,
    dashboardDateRange,
    fetchDashboardDateRange,
    fetchScenarioStatistics
  } = useContext(LassieContext);

  const [seasonalStatisticsYears, setSeasonalStatisticsYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedYears, setSelectedYears] = useState(["Last 365 Days"]);
  const [showDetailedActivity, setShowDetailedActivity] = useState(false);
  const [isDaysChart, setIsDaysChart] = useState(false);

  let { windfarm_id: wf_id } = useParams();

  const windfarm_id = useMemo(() => {
    if (wf_id) {
      return parseInt(wf_id);
    }
    return null;
  }, [wf_id]);

  const selectedWfStats = useMemo(() => {
    if (!windfarm_id || !windfarmStatistics || windfarmStatistics.length === 0)
      return null;
    return windfarmStatistics.find((s) => s.id === windfarm_id);
  }, [windfarm_id, windfarmStatistics]);

  const dates = useMemo(() => {
    if (!selectedYears.length) return null;

    if (selectedYears.length === 1 && selectedYears[0] === 'Last 365 Days') {
      let endDate = new Date();
      let startDate = new Date(new Date().setDate(endDate.getDate() - 365));

      return {
        start: toPrettyDateStr(startDate),
        end: toPrettyDateStr(endDate),
      };
    } else {
      const startYear = Math.min(...selectedYears);
      const endYear = Math.max(...selectedYears);

      const startDate = new Date(`${startYear}-01-01`);
      const endDate = new Date(`${endYear + 1}-01-01`);

      return {
        start: toPrettyDateStr(startDate),
        end: toPrettyDateStr(endDate),
      };
    }
  }, [selectedYears]);


  const minYear = useMemo(() => {
    if (!dashboardDateRange.length) return null;
    if (windfarm_id) {
      const matchedWindfarm = dashboardDateRange.find((y) => y.id === windfarm_id);
      return matchedWindfarm ? matchedWindfarm.min_year : null;
    }

    return Math.min(...dashboardDateRange.map((y) => y.min_year));
  }, [dashboardDateRange, windfarm_id]);


  const maxYear = useMemo(() => {
    if (!dashboardDateRange.length) return null;
    if (windfarm_id) {
      const matchedWindfarm = dashboardDateRange.find((y) => y.id === windfarm_id);
      return matchedWindfarm ? matchedWindfarm.max_year : null;
    }
    return Math.max(...dashboardDateRange.map((y) => y.max_year));
  }, [dashboardDateRange, windfarm_id])

  const {
    monthlyLightningData,
    monthlyDayData,
    averageLightningData,
    averageDayData,
    maxLightningCount,
    maxDaysCount,
  } = useMemo(
    () => processDataForCharts(seasonalStatistics, selectedYear),
    [seasonalStatistics]
  );

  const indicators = useMemo(() => {
    if (!windfarmStatistics || windfarmStatistics.length === 0) return [];

    let totalStrikes = 0;
    let outsideIec = 0;

    let mostActiveWindfarm = windfarmStatistics
      .map((s) => ({
        name: s.name,
        perTurbineAvg: s.total / s.turbine_count,
      }))
      .sort((a, b) => b.perTurbineAvg - a.perTurbineAvg)[0].name;

    windfarmStatistics.forEach((s) => {
      totalStrikes += s.total;
      outsideIec += s.outside_iec;
    });

    return [
      {
        title: "Wind farm with the highest lightning activity.",
        value: mostActiveWindfarm,
        image: windfarmIcon,
      },
      {
        title: "Total Strikes",
        value: totalStrikes,
        cardClusterId: 2,
      },
      {
        title: "Strikes outside IEC 2010",
        value: outsideIec,
        cardClusterId: 2,
      },
    ];
  }, [windfarmStatistics]);

  const avgStrikeWithin500m = useMemo(() => {
    if (!windfarmStatistics) return {};
    const res = {};
    windfarmStatistics.forEach(
      (s) => (res[s.id] = Math.round((s.total / s.turbine_count) * 10) / 10)
    );
    return res;
  }, [windfarmStatistics]);

  useEffect(() => {
    if (!dates) return
    fetchData()
  }, [windfarm_id])

  useEffect(() => {
    if (!windfarm_id) return;
    if (seasonalStatistics.length > 0) {
      const uniqueYears = extractUniqueYears(seasonalStatistics);
      setSeasonalStatisticsYears(uniqueYears);
      setSelectedYear(Math.max(...uniqueYears));
    }
  }, [seasonalStatistics, windfarm_id]);

  const fetchData = () => {
    if (!dates) return
    if (!windfarm_id) {
      fetchStatisticsData(dates.start, dates.end);
      fetchTurbineStatistics(dates.start, dates.end);
      if (!dashboardDateRange.length) {
        fetchDashboardDateRange()
      }

    } else {
      fetchScenarioStatistics(dates.start, dates.end, windfarm_id)
      fetchStatisticsData(dates.start, dates.end, windfarm_id);
      fetchTurbineStatistics(dates.start, dates.end, windfarm_id);
      fetchSeasonalStatistics(windfarm_id)
      if (!dashboardDateRange.length) {
        fetchDashboardDateRange()
      }
    }
  }

  const handleWindfarmChange = (name) => {
    if (name === "All windfarms") {
      navigate("/lassie/dashboard");
    } else {
      const wf = windfarms.find((w) => w.name === name);
      if (wf) {
        navigate(`/lassie/dashboard/windfarm/${wf.id}`);
      }
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  const handleWFYearChange = (year) => {
    if (year === "Last 365 Days") {
      setSelectedYears(["Last 365 Days"]);
      return;
    }

    if (selectedYears.includes(year)) {
      const updatedYears = selectedYears.filter((y) => y !== year);

      const isContiguous =
        updatedYears.length === 0 ||
        updatedYears.every((y, i, arr) => i === 0 || arr[i - 1] + 1 === y);

      if (isContiguous) {
        setSelectedYears(updatedYears);
      } else {
        console.warn("Selections must remain contiguous!");
      }
    } else {
      const sortedYears = [...selectedYears.filter((y) => y !== "Last 365 Days"), year].sort();

      const isContiguous = sortedYears.every((y, i, arr) => i === 0 || arr[i - 1] + 1 === y);

      if (isContiguous) {
        setSelectedYears(sortedYears);
      } else {
        setSelectedYears([year]);
      }
    }
  };

  const yearRange = (startYear, endYear) => {
    if (!startYear || !endYear || startYear > endYear) return [];
    const range = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
    range.push("Last 365 Days");
    return range;
  };

  const isDataReady = !windfarm_id
    ?
    [windfarmStatistics, turbineStatistics, dashboardDateRange].every(
      arr => arr.length > 0
    )
    : [windfarmStatistics, turbineStatistics, dashboardDateRange, seasonalStatistics, scenarioStatistics].every(
      arr => arr.length > 0
    );


  useSyncSelectedWindfarm(windfarm_id);

  return (
    <div className="lightning-dashboard">
      <h1>Dashboard</h1>
      {isDataReady ? (
        <>
          <p className="dashboard-disclaimer">Data displayed within a 500 meters radius of each turbine</p>
          <div className="dashboard-select">
            {windfarms && (
              <WplInput
                title="Select a windfarm"
                className="no-risk-windfarms"
                options={[
                  "All windfarms",
                  ...windfarms.map((wf) => wf.name),
                ]}
                onlySelectableOptions
                value={selectedWfStats ? selectedWfStats.name : "All windfarms"}
                placeholder="Select a windfarm..."
                onChanged={handleWindfarmChange}
              />
            )}
            <h2 className="period-title">
              {selectedWfStats && selectedWfStats.name}
            </h2>

          </div>

          {seasonalStatistics.length > 0 && windfarm_id && (
            <div className="seasonal-statistics-area">
              <h4>Seasonal Statistics</h4>
              <div className="from-to-date-wrapper">
                <WplButton
                  className={`year-option ${!isDaysChart ? "selected" : ""}`}
                  value="Lightning strikes"
                  onClick={() => setIsDaysChart(false)}
                />
                <WplButton
                  className={`year-option ${isDaysChart ? "selected" : ""}`}
                  value="Lightning days"
                  onClick={() => setIsDaysChart(true)}
                />
              </div>
              <div className="from-to-date-wrapper">
                {seasonalStatisticsYears.map((year) => {
                  return (
                    <WplButton
                      className={`year-option ${selectedYear === year ? "selected" : ""
                        }`}
                      value={year}
                      key={year}
                      onClick={handleYearChange}
                    />
                  );
                })}
              </div>
              <div className="seasonal-statistics-wrapper">
                {isDaysChart ? (
                  <ComposedChartComponent
                    monthlyData={monthlyDayData}
                    averageData={averageDayData}
                    maxCount={maxDaysCount}
                    isDaysChart={isDaysChart}
                    selectedYear={selectedYear}
                  />
                ) : (
                  <ComposedChartComponent
                    monthlyData={monthlyLightningData}
                    averageData={averageLightningData}
                    maxCount={maxLightningCount}
                    isDaysChart={isDaysChart}
                    selectedYear={selectedYear}
                  />
                )}
              </div>
            </div>
          )}

          <p className="dashboard-disclaimer">Select a date range by clicking <b>consecutive</b> years or 'Last 365 days.'.</p>
          <div className="from-to-date-wrapper">
            {yearRange(minYear, maxYear).map((year) => (
              <WplButton
                className={`year-option ${selectedYears.includes(year) ? "selected" : ""}`}
                value={year}
                key={year}
                onClick={() => handleWFYearChange(year)}
              >
                {year}
              </WplButton>
            ))}
          </div>
          <div className="fetch-btn-wrapper">
            <button className={`fetch-btn ${!selectedYears.length ? 'disabled' : null}`} onClick={fetchData}>
              Fetch data
            </button>
          </div>

          {!windfarm_id && indicators && (
            <DashboardCard statistics={indicators} />
          )}

          {selectedWfStats && (
            <div>
              <DashboardCard
                statistics={[
                  {
                    title: "Turbines installed",
                    value: selectedWfStats.turbine_count,
                    image: windfarmIcon,
                  },
                  {
                    title: "Total Strikes",
                    value: selectedWfStats.total,
                  },
                  {
                    title: "No. outside IEC 2010",
                    value: selectedWfStats.outside_iec,
                  },
                  {
                    title: "Average strikes",
                    value:
                      Math.round(
                        (selectedWfStats.total /
                          selectedWfStats.turbine_count) *
                        10
                      ) / 10,
                  },
                ]}
              />
            </div>
          )}

          {turbineStatistics && windfarm_id && (
            <div>
              <h4>Theoretical risk overview</h4>
              <DashboardCard
                statistics={[
                  {
                    title: "Theoretical expected attachments",
                    value:
                      Math.round(
                        turbineStatistics.reduce(
                          (p, c) => p + c.expected_value,
                          0
                        ) * 10
                      ) / 10,
                  },
                  {
                    title: "Turbines with no lightning activity",
                    value: turbineStatistics.filter(
                      (ts) => ts.expected_value <= 1
                    ).length,
                  },
                  {
                    title: "Turbines with more than 1 strike",
                    value: turbineStatistics.filter(
                      (ts) => ts.expected_value > 1
                    ).length,
                  },
                ]}
              />
            </div>
          )}

          {!windfarm_id && windfarmStatistics && (
            <>
              <h4>Average lightning activity</h4>
              <table className="dashboard-table chart-table">
                <thead>
                  <tr>
                    <th className="dashboard-th">Windfarm</th>
                    <th className="dashboard-th">Turbines</th>
                    <th className="chart-header">
                      Average strikes per turbine
                      <div className="chart-reference">
                        <span className="chart-reference-number">0</span>
                        <span className="chart-reference-number">
                          {Math.ceil(Math.max(...Object.values(avgStrikeWithin500m)) * 10) / 10}
                        </span>
                      </div>
                      <div className="chart-reference-line"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {windfarmStatistics
                    .sort((a, b) => (avgStrikeWithin500m[b.id] || 0) - (avgStrikeWithin500m[a.id] || 0))
                    .map((s) => (
                      <tr key={s.id}>
                        <td className="windfarm-link dashboard-td">
                          <Link to={`/lassie/dashboard/windfarm/${s.id}`}>
                            {s.name}
                          </Link>
                        </td>
                        <td className="dashboard-td">{s.turbine_count}</td>
                        <td className="dashboard-td">
                          {avgStrikeWithin500m[s.id] > 0 ? (
                            <div className="bar-container">
                              <div
                                style={{
                                  width: `${(avgStrikeWithin500m[s.id] /
                                    Math.max(...Object.values(avgStrikeWithin500m))) * 100}%`,
                                }}
                                className="bar-chart-column"
                              >
                                {avgStrikeWithin500m[s.id]}
                              </div>
                            </div>
                          ) : (
                            <div className="zero-strike">{avgStrikeWithin500m[s.id]}</div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}

          {turbineStatistics && (
            <div className="dashboard-table-container">
              <h4>Turbines with most lightning activity</h4>
              <table
                className={
                  showDetailedActivity
                    ? "dashboard-table table-custom-width"
                    : "dashboard-table"
                }
              >
                <thead>
                  <tr>
                    {!windfarm_id && <th>Windfarm</th>}
                    <th className="dashboard-th">Turbine</th>
                    <th className="dashboard-th">
                      {"Theoretical Attachment Count"}
                    </th>
                    {showDetailedActivity && (
                      <th className="dashboard-th">{"<500m"}</th>
                    )}
                    {showDetailedActivity && (
                      <th className="dashboard-th">{"<200m"}</th>
                    )}
                    {showDetailedActivity && (
                      <th className="dashboard-th">{"<100m"}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {turbineStatistics
                    .filter((_, idx) => idx < 8)
                    .map((s, i) => (
                      <tr key={i}>
                        {!windfarm_id && (
                          <td className="dashboard-td">{s.windfarm_name}</td>
                        )}
                        <td className="dashboard-td">{s.turbine_name}</td>
                        <td className="dashboard-td">
                          {Math.round(s.expected_value * 10) / 10}
                        </td>
                        {showDetailedActivity && (
                          <td className="dashboard-td">{s["500m"]}</td>
                        )}
                        {showDetailedActivity && (
                          <td className="dashboard-td">{s["200m"]}</td>
                        )}
                        {showDetailedActivity && (
                          <td className="dashboard-td">{s["100m"]}</td>
                        )}
                      </tr>
                    ))}
                </tbody>
                <tbody className="button-container">
                  <tr
                    className={`arrow-icon ${showDetailedActivity ? "open" : "closed"
                      }`}
                    onClick={() =>
                      setShowDetailedActivity(!showDetailedActivity)
                    }
                  ></tr>
                </tbody>
              </table>
            </div>
          )}

          {windfarm_id && scenarioStatistics && (
            <>
              <h4>Notifications overview</h4>
              <div className="pie-area">
                {scenarioStatistics
                  .filter((ss) => ss.windfarm_id === windfarm_id)
                  .map((ss, i) => (
                    <div key={i} className="pie-wrapper">
                      <h4 className="pie-title">{ss.trigger_title}</h4>
                      <p className="pie-data">
                        {Math.round((ss.handled / ss.events) * 100)}% handled
                      </p>
                      <PieChartComponent
                        data={[
                          {
                            text: "Triggers",
                            value: 1 - ss.handled / ss.events,
                          },
                          {
                            text: "Handled",
                            value: ss.handled / ss.events,
                          },
                        ]}
                        innerRadius={50}
                        outerRadius={80}
                        colors={["#a52a2a", "#7cbb42"]}
                      />
                    </div>
                  ))}
              </div>
            </>
          )}
        </>
      ) : (
        <div className="overlay loading">
          <TurbineAnimation />
        </div>
      )}
    </div>
  );
}
