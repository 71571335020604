import React, {useState, useEffect} from 'react'
import {reportAPI, segmentationAPI} from "../../../api";
import WplInput from "../../../components/wpl-input/WplInput";
import './segmentation-admin.css'
import WplButton from "../../../components/wpl-button/WplButton";
import StreamedSurface from "./StreamedSurface";
import WplPopup from "../../../components/wpl-popup-window/WplPopup";
import CompareSegmentationResults from "./compare-segmentation-results/CompareSegmentationResults";

export default function SegmentationAdminPage() {
    const [reports, setReports] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [startedStreams, setStartedStreams] = useState([]);

    const [status, setStatus] = useState(null);

    const [displaySurface, setDisplaySurface] = useState(null);

    useEffect(() => {
        reportAPI.listReports().then(setReports)
    }, []);

    useEffect(() => {
        if (!selectedReport) return;
        segmentationAPI.listNewStatus(selectedReport.id).then(setStatus)
    }, [selectedReport]);

    return (<div className='segmentation-admin-page'>
        <WplInput
            onlySelectableOptions
            options={reports.map(r => r.title)}
            value={selectedReport ? selectedReport.title : ''}
            onChanged={title => setSelectedReport(reports.find(r => r.title === title))}
        />
        {status && Object.keys(status).map(turbine_key => {
            const turbine = status[turbine_key]

            return <div className='turbine' key={turbine_key}>
                <h2>{turbine_key}</h2>
                <div className='horizontal'>
                    {Object.keys(turbine).map(blade_name => {
                        const blade = turbine[blade_name]

                        return <div className='blade' key={blade_name}>
                            <h3>{blade_name}</h3>
                            <table>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th>Pics</th>
                                    <th>Unets</th>
                                    <th>RF</th>
                                    <th>Final</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.keys(blade).map(surface_name => {
                                    const {
                                        picture_count,
                                        unet_mask_count,
                                        rf_id,
                                        final_count
                                    } = blade[surface_name]

                                    if (startedStreams.indexOf(`${turbine_key}-${blade_name}-${surface_name}`) !== -1) {
                                        return <StreamedSurface
                                            key={surface_name}
                                            status={blade[surface_name]}
                                            report_id={selectedReport.id}
                                            turbine={turbine_key}
                                            blade={blade_name}
                                            surface={surface_name}
                                        />
                                    } else {
                                        return <tr className='surface' key={surface_name}>
                                            <td onClick={_ => setDisplaySurface({
                                                'report_id': selectedReport.id,
                                                'turbine': turbine_key,
                                                'blade': blade_name,
                                                'surface': surface_name
                                            })}>{surface_name}</td>
                                            <td>{picture_count}</td>
                                            <td className={unet_mask_count === picture_count ? 'green' : 'red'}>{unet_mask_count}
                                            </td>
                                            <td className={rf_id ? 'green' : 'red'}>{rf_id ? 'yes' : 'no'}</td>
                                            <td className={final_count === picture_count ? 'green' : 'red'}>{final_count}
                                            </td>
                                        </tr>
                                    }
                                })}
                                </tbody>
                            </table>
                            <WplButton value='run' big onClick={_ => {
                                console.log('Running!')
                                console.log(Object.keys(blade))
                                setStartedStreams(prev => ([
                                    ...prev,
                                    ...Object.keys(blade).map(surface => `${turbine_key}-${blade_name}-${surface}`)
                                ]))
                            }}/>
                        </div>
                    })}
                </div>
            </div>
        })}
        {displaySurface && <WplPopup showPopup={displaySurface} closePopup={_ => setDisplaySurface(null)}>
            <CompareSegmentationResults {...displaySurface} />
        </WplPopup>}
    </div>)
}
