import React, {useCallback, useEffect, useState} from 'react'
import RegionDataOverview from "../region-data-overview/RegionDataOverview";
import {useParams} from "react-router-dom";
import './lassie-region-page.css';
import {heavyLassieRegionApi} from "../../../heavy_duty_api";

export default function LassieRegionPage() {
    const {region_id} = useParams();

    const [selectedRegion, setSelectedRegion] = useState(null);
    const [limit_history_days, setLimitHistoryDays] = useState(30);
    const [loading, setLoading] = useState(false);

    const fetchRegion = useCallback(() => {
        console.log('fetchRegion', region_id)
        if (!region_id) {
            setSelectedRegion(null)
            return
        }
        setLoading(true);
        heavyLassieRegionApi.listRegions([region_id], limit_history_days).then(rs => {
            setLoading(false);
            if (rs.length !== 1) {
                console.log('region not found?', rs)
                setSelectedRegion(null)
                return
            }
            const region = rs[0]
            const coords = region.polygon
                .substring(9, region.polygon.length - 2)
                .split(',')
                .map(s => s.split(' ').map(parseFloat).reverse())

            const latTot = coords.reduce((prev, curr) => prev + curr[1], 0)
            const lonTot = coords.reduce((prev, curr) => prev + curr[0], 0)
            const count = coords.length
            const center = [Math.floor(latTot / count * 10000) / 10000, Math.floor(lonTot / count * 10000) / 10000]

            console.log('SEtting region', {
                ...region,
                center,
                polygon_coordinates: coords
            })
            setSelectedRegion({
                ...region,
                center,
                polygon_coordinates: coords
            })
        });
    }, [region_id, limit_history_days]);

    useEffect(() => {
        console.log('useEffect', region_id)
        fetchRegion()
    }, [region_id]);

    return (<main>
            <a href='/admin/lightning/data'>back</a>
            <div>
                <label>
                    Fetch history for days:
                    <input type={'number'} value={limit_history_days}
                           onChange={e => setLimitHistoryDays(e.target.value)}/>
                </label>
                <button onClick={fetchRegion} disabled={loading}>Update</button>
            </div>
            {loading && <h2>Loading...</h2>}
            {selectedRegion !== null && <RegionDataOverview selectedRegion={selectedRegion} setSelectedRegion={setSelectedRegion}/>}
        </main>
    )
}
