import React, { useState, useEffect, useCallback } from "react";
import "./admin-page.css";
import WplButton from "../../components/wpl-button/WplButton";
import WplInput from "../../components/wpl-input/WplInput";
import { datamanagementAPI, userAPI, windfarmAPI } from "../../api";
import WplPopup from "../../components/wpl-popup-window/WplPopup";
import SimpleTable from "../../components/simple-table/SimpleTable";
import WplTabs from "../../components/wpl-tabs/WplTabs";
import AdminHeader from "./admin-header/AdminHeader";
import WplSwitch from "../../components/wpl-switch/wplSwitch";

export default function AdminPage(props) {
  const [showInvitationWindow, setShowInvitationWindow] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [selectedInvitationWindfarms, setSelectedInvitationWindfarms] = useState([]);
  const [hasLassieAccess, setHasLassieAccess] = useState(false);
  const [selectedScope, setSelectedScope] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);
  const [selectedRockyOrganization, setSelectedRockyOrganization] = useState(null);

  const [invitationLink, setInvitationLink] = useState("");
  const [showInviteCreatedPopup, setShowInviteCreatedPopup] = useState(false);

  const [showResetCreated, setShowResetCreated] = useState(false);
  const [resetLink, setResetLink] = useState("");

  const [users, setUsers] = useState([]);
  const [invitations, setInvitations] = useState([]);

  const [allWindfarms, setAllWindfarms] = useState([]);

  const [allRockyOrganizations, setAllRockyOrganizations] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [newUserScope, setNewUserScope] = useState("");

  useEffect(() => {
    userAPI.listUsers(false).then(setUsers);
    userAPI.listInvitations().then(setInvitations);
    windfarmAPI.listAllWindfarms().then(setAllWindfarms);
    datamanagementAPI.listRockyOrganizations().then(setAllRockyOrganizations);
  }, []);

  const sendInvitation = useCallback(() => {
    const ids = selectedInvitationWindfarms.map(
      (selected) => allWindfarms.find((wf) => wf.name === selected).id,
    );

    const rocky_organization_id = selectedRockyOrganization ? allRockyOrganizations.find((org) => org.name === selectedRockyOrganization).id : null;

    return userAPI
      .createInvitation(userName, userEmail, ids, hasLassieAccess, selectedScope, sendEmail, { rocky_organization_id })
      .then((il) => {
        setShowInvitationWindow(false);
        setInvitationLink(il);
        setShowInviteCreatedPopup(true);
        setUserEmail("");
        setUserName("");
        setSelectedInvitationWindfarms([]);
        setHasLassieAccess(false);
        setSendEmail(false);
        setSelectedRockyOrganization(null);
      })
      .catch((err) => {
        alert(err.response.data);
      });
  }, [userEmail, userName, selectedInvitationWindfarms, hasLassieAccess, selectedScope, selectedRockyOrganization, sendEmail]);

  function resetPassword() {
    if (!window.confirm("Reset user password?")) return;

    return userAPI.createResetPasswordRequest(selectedUser.id).then((link) => {
      setResetLink(link);
      setShowResetCreated(true);
    });
  }

  const renderInvitationRow = useCallback(
    (row) => {
      const accepted = users.some((u) => u.invitation_id === row.id);
      return (
        <tr key={row.id}>
          <td>{row.token}</td>
          <td>{row.email}</td>
          <td className={`accepted-td ${accepted}`}>
            {accepted ? "Yes" : "No"}
          </td>
        </tr>
      );
    },
    [users],
  );

  const didSelectUser = useCallback(
    (email) => {
      const u = users.find((u) => u.email === email);
      if (!u) {
        setSelectedUser(null);
        setNewUserScope("");
        return;
      }
      userAPI.listUserWindfarms(u.id).then((user_wfs) => {
        setSelectedUser({
          ...u,
          windfarms: user_wfs,
        });
      });
      setNewUserScope(u.scope);
    },
    [users, setSelectedUser],
  );

  function renderUserWindfarm(windfarm) {
    if (!selectedUser) return;
    return (
      <tr key={windfarm.id}>
        <td>{windfarm.customer}</td>
        <td>{windfarm.name}</td>
        <td>
          <WplTabs
            tabTitles={["ON", "OFF"]}
            selected={
              selectedUser.windfarms.some((wf) => wf.name === windfarm.name)
                ? "ON"
                : "OFF"
            }
            setSelected={(r) => {
              if (r === "ON") {
                userAPI
                  .addUserToWindfarm(selectedUser.id, windfarm.id)
                  .then((user_wfs) => {
                    setSelectedUser((prev) => ({
                      ...prev,
                      windfarms: user_wfs,
                    }));
                  });
              } else {
                userAPI
                  .removeUserFromWindfarm(selectedUser.id, windfarm.id)
                  .then((user_wfs) => {
                    setSelectedUser((prev) => ({
                      ...prev,
                      windfarms: user_wfs,
                    }));
                  });
              }
            }}
          />
        </td>
      </tr>
    );
  }

  return (
    <div className="admin-page">
      <WplPopup
        className="inv-popup"
        showPopup={showInvitationWindow}
        closePopup={setShowInvitationWindow}
      >
        <h2>Creating an invitation</h2>
        <WplInput title="First name" value={userName} onChanged={setUserName}/>
        <WplInput title="Email" value={userEmail} onChanged={setUserEmail}/>

        <WplInput
          title="Scope"
          options={['wind-diagnostics', 'rocky', 'employee', 'developer']}
          onlySelectableOptions={true}
          onChanged={(newScope) => {
            setSelectedScope(newScope);
            setSelectedRockyOrganization(null);
            setSelectedInvitationWindfarms([]);
          }}
          value={selectedScope}
        />

        {selectedScope && selectedScope === 'rocky' && <WplInput
          title="Rocky organizations"
          options={allRockyOrganizations
            .map((org) => org.name)
            .sort((org1, org2) => org1.localeCompare(org2))
          }
          onlySelectableOptions={true}
          onChanged={setSelectedRockyOrganization}
          value={selectedRockyOrganization}
        />}

        {selectedScope && selectedScope !== 'rocky' && <WplInput
          title="Windfarms"
          options={allWindfarms
            .map((wf) => wf.name)
            .sort((wf1, wf2) => wf1.localeCompare(wf2))}
          onlySelectableOptions={true}
          onChanged={setSelectedInvitationWindfarms}
          value={selectedInvitationWindfarms}
          multiSelect={true}
        />}
        {selectedScope && selectedScope !== 'rocky' && <WplSwitch
          title={"Lassie access"}
          value={hasLassieAccess}
          onChanged={() => setHasLassieAccess(!hasLassieAccess)}
          className="invitation-switch"
          labelClassname={"invitation-switch"}
        />}
        <WplSwitch
          title={"Send email"}
          value={sendEmail}
          onChanged={() => setSendEmail(!sendEmail)}
          className="invitation-switch"
          labelClassname={"invitation-switch"}
        />
        <WplButton value="Generate invitation" onClick={sendInvitation}/>
      </WplPopup>
      <WplPopup
        className="inv-popup"
        showPopup={showInviteCreatedPopup}
        closePopup={setShowInviteCreatedPopup}
      >
        <h3>Invitation created successfully!</h3>
        <p>Send this link to our client:</p>
        <h4>{invitationLink}</h4>
      </WplPopup>
      <WplPopup
        className="inv-popup"
        showPopup={showResetCreated}
        closePopup={setShowResetCreated}
      >
        <h3>User password reset request created.</h3>
        <p>Send this link to our client:</p>
        <h4>{resetLink}</h4>
      </WplPopup>
      <div>
        <AdminHeader/>
        <h2>Admin page</h2>
        <div className="area-wrapper">
          <div className="user-area">
            <h3>Users</h3>
            <WplInput
              title="Edit user"
              options={users
                .map((u) => u.email)
                .sort((u1, u2) => u1.localeCompare(u2))}
              value={selectedUser ? selectedUser.email : ""}
              onChanged={didSelectUser}
              onlySelectableOptions
              clearable
            />
            {selectedUser && (
              <div>
                <WplButton value="Reset Password" onClick={resetPassword}/>
                <div className="set-scope-wrapper">
                  <WplInput
                    title="Set user scope"
                    onlySelectableOptions
                    options={[
                      "developer",
                      "employee",
                      "wind-diagnostics",
                      "rocky",
                    ]}
                    value={newUserScope}
                    onChanged={setNewUserScope}
                  />
                  <WplButton
                    value="Save"
                    onClick={(_) =>
                      userAPI
                        .updateScope(selectedUser.id, newUserScope)
                        .then((_) => {
                          userAPI.listUsers(false).then(setUsers);
                          setSelectedUser((prev) => ({
                            ...prev,
                            scope: newUserScope,
                          }));
                        })
                    }
                    disabled={newUserScope === selectedUser.scope}
                  />
                </div>
                <h4>User access to windfarms</h4>
                <div className="table-scroll">
                  <SimpleTable
                    className="user-windfarm-list"
                    renderRow={renderUserWindfarm}
                    headers={["Customer", "Windfarm", "Add/Remove"]}
                    rows={allWindfarms.sort((a, b) => {
                      if (a.customer !== b.customer) {
                        return a.customer.localeCompare(b.customer);
                      }
                      return a.name.localeCompare(b.name);
                    })}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="invitation-area">
            <h3>Invitations</h3>
            <WplButton
              value="Create new invitation"
              onClick={() => setShowInvitationWindow(true)}
            />
            <div className="table-scroll">
              <SimpleTable
                renderRow={renderInvitationRow}
                headers={["Token", "Email", "Accepted"]}
                rows={invitations.sort((i1, i2) => {
                  const i1_acc = users.some((u) => u.invitation_id === i1.id);
                  const i2_acc = users.some((u) => u.invitation_id === i2.id);

                  if (i1_acc !== i2_acc) {
                    return i2_acc - i1_acc;
                  }
                  return i1.email.localeCompare(i2.email);
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
