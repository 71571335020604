import React, { useRef, useState } from "react";
import "./turbine-manager-popup.css";
import closeIcon from '../../assets/img/cancel.png'

export default function TurbineManagerPopup({
  children,
  showPopup,
  closePopup,
  className,
}) {
  const me = useRef(null);

  const [downTarget, setDownTarget] = useState("");

  if (!showPopup) return <div></div>;

  return (
    <div
      ref={me}
      className="turbine-manager-popup"
      onMouseDown={(e) => setDownTarget(e.target)}
      onMouseUp={(e) => {
        if (e.target !== me.current) return;
        if (downTarget !== me.current) return;
        closePopup(false);
      }}
    >
      <div
        className={`popup-wrapper ${className}`}
        onMouseDown={(e) => setDownTarget(e.target)}
      >
        <div className="close-btn-container">
          <img
            className="close-btn"
            onClick={(_) => closePopup(false)}
            src={closeIcon}
            alt="close popup"
          />
        </div>
        {children}
      </div>
    </div>
  );
}
