import './info-card.css';
import { toPrettyTimeSince } from "../../prettyDate";

export default function InfoCards({ statistics, windfarmPage }) {
    if (!statistics) return null;

    const renderCard = (entry, pretty_value) => (
        <div key={entry.title} className={`info-card ${entry.color ? entry.color : ''}`}>
            <p className='info-card-title'>{entry.title}</p>
            <h4 className='value'>{pretty_value}</h4>
        </div>
    );

    if (windfarmPage) {
        const lastTwoWeeksEntry = statistics.find(entry => entry.title === "Last two weeks");
        const outsideIECEntry = statistics.find(entry => entry.title === "Unhandled strikes outside IEC (last two weeks)");

        const otherEntries = statistics.filter(entry => 
            entry.title !== "Last two weeks" && 
            entry.title !== "Unhandled strikes outside IEC (last two weeks)"
        );

        return (
            <div className='info-card-wrapper'>
                {lastTwoWeeksEntry && outsideIECEntry && (
                    <div className='info-card clustered'>
                        <p className='info-card-title'>{lastTwoWeeksEntry.title}</p>
                        <p className='clustered-value'>{lastTwoWeeksEntry.value}</p>
                        <p className='info-card-title'>Outside IEC</p>
                        <p className='clustered-value'>{outsideIECEntry.value} strikes</p>
                    </div>
                )}

                {otherEntries.map(entry => {
                    let pretty_value = entry.value;
                    if (entry.format === 'timestamp') {
                        pretty_value = toPrettyTimeSince(new Date(pretty_value));
                    }
                    return renderCard(entry, pretty_value);
                })}
            </div>
        );
    }

    return (
        <div className='info-card-wrapper'>
            {statistics.map(entry => {
                let pretty_value = entry.value;
                if (entry.format === 'timestamp') {
                    pretty_value = toPrettyTimeSince(new Date(pretty_value));
                }
                return renderCard(entry, pretty_value);
            })}
        </div>
    );
}