import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LassieContext } from "../../../context/LassieProvider";
import { toPrettyDateStr, toStandardWplTimestamp } from "../../../prettyDate";
import { useSyncSelectedWindfarm } from "../../../hooks/useSyncSelectedWindfarm ";
import OlMap from "../../../components/ol-map/OlMap";
import InfoCards from "../../../components/info-card/InfoCard";
import { getIconForTurbine } from "../../../components/ol-map/icons";
import WplButton from "../../../components/wpl-button/WplButton";
import TurbineLightningRuler from "../../../components/turbine-lightning-ruler/TurbineLightningRuler";
import WplInput from "../../../components/wpl-input/WplInput";
import TurbineAnimation from "../../../components/turbine-animation/TurbineAnimation";
import icon from "../../../assets/img/icon-info.png";
import arrowIcon from "../../../assets/img/arr_down.png";
import "./lightning-report.css";

export default function LightningLogbook() {
  const navigate = useNavigate();

  const {
    windfarms,
    fetchScenarios,
    scenarios,
    fetchNotifications,
    notifications,
    setNotifications,
    fetchHistoricActivity,
    historicActivity,
    setHistoricActivity,
    fetchHistoricUserAction,
    historicUserAction,
    setHistoricUserAction,
    fetchHistoricTriggers,
    historicTriggers,
    setHistoricTriggers
  } = useContext(LassieContext);

  const [selectedWindfarm, setSelectedWindfarm] = useState(null);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [loading, setLoading] = useState(false);

  let { windfarm_id } = useParams();
  if (windfarm_id) {
    windfarm_id = parseInt(windfarm_id);
  }

  useEffect(() => {
    if (!windfarm_id || !windfarms) return;
    if (selectedWindfarm && selectedWindfarm.id === windfarm_id) return;

    setLoading(true);
    setSelectedWindfarm(windfarms.find((wf) => wf.id === windfarm_id));
    setNotifications(null);
    setSelectedNotification(null);
    setHistoricActivity(null);
    setHistoricUserAction(null);
    setHistoricTriggers(null);
    setLoading(false);
  }, [selectedWindfarm, windfarm_id, windfarms]);

  useEffect(() => {
    if (!windfarm_id) return;

    const fetchData = async () => {
      setYears([]);
      setLoading(true);
      await fetchScenarios(windfarm_id);
      await fetchNotifications(windfarm_id);
      setLoading(false);
    };

    fetchData();
  }, [windfarm_id]);

  useEffect(() => {
    if (!selectedNotification || !windfarm_id) return;

    const fetchHistoricData = async () => {
      setLoading(true);
      const lastTimestamp = new Date(
        selectedNotification.last_lightning_timestamp
      );
      const from_timestamp = toStandardWplTimestamp(
        new Date(selectedNotification.first_lightning_timestamp)
      );
      const to_timestamp = toStandardWplTimestamp(lastTimestamp);
      const three_weeks_range_timestamp = toStandardWplTimestamp(
        new Date(lastTimestamp.setDate(lastTimestamp.getDate() + 21))
      );

      await fetchHistoricActivity(windfarm_id, from_timestamp, to_timestamp);
      await fetchHistoricUserAction(
        windfarm_id,
        from_timestamp,
        three_weeks_range_timestamp
      );
      await fetchHistoricTriggers(windfarm_id, from_timestamp, to_timestamp);
      setLoading(false);
    };

    fetchHistoricData();
  }, [selectedNotification, windfarm_id]);

  useEffect(() => {
    if (!notifications) return;
    const extractedYears = Object.keys(notifications)
      .map((year) => parseInt(year, 10))
      .sort((a, b) => a - b);

    setYears(extractedYears);
  }, [notifications]);

  useSyncSelectedWindfarm(windfarm_id);

  return (
    <div className="lightning-report">
      <h1>Logbook</h1>
      {loading && <TurbineAnimation />}
      <div className="windfarm-input-selector">
        <div className="lightning-report-select">
          <WplInput
            title="Select a windfarm"
            className="no-risk-windfarms"
            options={windfarms.map((wf) => wf.name)}
            onlySelectableOptions
            value={selectedWindfarm ? selectedWindfarm.name : ""}
            placeholder={"Select a windfarm..."}
            onChanged={(name) => {
              const wf = windfarms.find((w) => w.name === name);
              if (wf) {
                navigate(`/lassie/logbook/windfarm/${wf.id}`);
              }
            }}
          />
        </div>

        <div className="from-to-date-wrapper">
          {years.map((year) => {
            return (
              <WplButton
                className={`year-option ${selectedYear === year ? "selected" : ""
                  }`}
                value={year}
                key={year}
                onClick={() => setSelectedYear(year)}
              />
            );
          })}
        </div>
      </div>

      {!loading && notifications && selectedYear && (
        <>
          <div className="windfarm-input-selector">
            <div className="notications-info-area">
              <h3 className="notifications-label">
                There were {notifications.length} notification
                {notifications.length === 1 ? "" : "s"} in this period.
                <span className="info-tooltip-icon">
                  <img src={icon} alt="info-tooltip" />
                  <p className="info-container">
                    Each notification suggests serious lightning activity close
                    to at least one turbine. In the following section, you will
                    see an overview of what happened and what the response was.
                  </p>
                </span>
              </h3>
            </div>

            <div className="notification-selection">
              {notifications &&
                selectedYear ?
                notifications[selectedYear].map((notif, i) => (
                  <div
                    key={i}
                    className={`selectable-notification ${selectedNotification &&
                      notif.date === selectedNotification.date
                      ? "selected"
                      : ""
                      }`}
                    onClick={() => setSelectedNotification(notif)}
                  >
                    <h4>
                      {toPrettyDateStr(
                        new Date(notif.first_lightning_timestamp)
                      )}
                    </h4>
                  </div>
                )) : null

              }
            </div>
          </div>

          {selectedNotification && historicActivity && (
            <div
              key={selectedNotification.first_lightning_timestamp}
              className="notification"
            >
              <div className="date-events-container">
                <div className="date-title">
                  <p>
                    <b>
                      {toPrettyDateStr(
                        new Date(selectedNotification.first_lightning_timestamp)
                      )}
                    </b>
                  </p>
                  <p>
                    {toPrettyDateStr(
                      new Date(selectedNotification.first_lightning_timestamp),
                      true
                    )
                      .split(" ")
                      .pop()}
                  </p>
                </div>
                <img className="arrow" src={arrowIcon} alt="arrow" />
                <div className="date-title">
                  <p>
                    <b>
                      {toPrettyDateStr(
                        new Date(selectedNotification.last_lightning_timestamp)
                      )}
                    </b>
                  </p>
                  <p>
                    {toPrettyDateStr(
                      new Date(selectedNotification.last_lightning_timestamp),
                      true
                    )
                      .split(" ")
                      .pop()}
                  </p>
                </div>
              </div>

              {historicActivity && (
                <OlMap
                  forceShiftkeyToZoom
                  lightning_strikes={[
                    ...historicActivity.map((t) => t.turbine_lightning)
                  ].flat()}
                  turbines={historicActivity}
                />
              )}

              <h3>Team response</h3>
              {historicUserAction && (
                <div className="user-action">
                  <p>
                    In the following <b>three</b> weeks, there was{" "}
                    {historicUserAction.length} registered user actions.
                  </p>
                  <InfoCards
                    statistics={[
                      {
                        value: historicUserAction.filter(
                          (hs) => hs.action === "inspection"
                        ).length,
                        title: "Inspections"
                      },
                      {
                        value: historicUserAction.filter(
                          (hs) => hs.action === "acceptable_risk"
                        ).length,
                        title: "Accepted Risk"
                      },
                      {
                        value: historicUserAction.filter(
                          (hs) => hs.document_id !== null
                        ).length,
                        title: "Uploaded Documents"
                      },
                      {
                        value: historicUserAction.filter(
                          (hs) => !!hs.user_comment
                        ).length,
                        title: "User Comments"
                      }
                    ]}
                  />
                </div>
              )}

              {historicActivity &&
                historicActivity
                  .filter((turbine) => turbine.turbine_lightning.length > 0)
                  .sort(
                    (at, bt) =>
                      bt.probability_of_atleast_one -
                      at.probability_of_atleast_one
                  )
                  .map((turbine) => {
                    let lightningDates = new Set(
                      turbine.turbine_lightning.map((tl) =>
                        toPrettyDateStr(new Date(tl.timestamp))
                      )
                    );
                    lightningDates = Array.from(lightningDates)
                      .map((d) => new Date(d))
                      .sort((a, b) => a - b);
                    const latestDate = lightningDates[0];

                    return (
                      <div className="notification-turbine" key={turbine.id}>
                        <h2
                          title={
                            {
                              acceptable_risk: "Managed",
                              at_risk: "At risk",
                              marked_for_inspection: "Marked for inspection"
                            }[turbine.status]
                          }
                        >
                          <img
                            style={{ height: "1em" }}
                            src={getIconForTurbine(turbine, false)}
                          />
                          {turbine.name}
                        </h2>
                        {lightningDates.map((k, i) => (
                          <p key={i}>{toPrettyDateStr(k)}</p>
                        ))}

                        <TurbineLightningRuler
                          strikes={turbine.turbine_lightning}
                        />

                        <table className="scenario-table">
                          <tbody>
                            <tr>
                              <th colSpan={2}>Triggered scenarios</th>
                            </tr>
                            {historicTriggers &&
                              historicTriggers
                                .filter((ht) => ht.turbine_id === turbine.id)
                                .map((ht) => {
                                  const sc = scenarios.find(
                                    (s) => s.id === ht.scenario_id
                                  );
                                  if (!sc) return;
                                  return (
                                    <tr key={sc.id}>
                                      <td>{sc.title}</td>
                                      <td>{ht.trigger_count}</td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>

                        {historicUserAction &&
                          historicUserAction
                            .filter((hs) => hs.turbine_id === turbine.id)
                            .sort(
                              (a, b) =>
                                new Date(a.target_date) -
                                new Date(b.target_date)
                            )
                            .map((hs) => {
                              const dayDiff = Math.round(
                                (new Date(hs.target_date) - latestDate) /
                                86400 /
                                1000
                              );
                              return (
                                <div key={hs.id} className="user-action">
                                  <p>
                                    {toPrettyDateStr(new Date(hs.target_date))}{" "}
                                    {!!dayDiff &&
                                      `(${dayDiff} day${dayDiff > 1 ? "s" : ""
                                      } later)`}
                                  </p>
                                  <b>Set status {hs.action}</b>
                                  {hs.document_id && (
                                    <p>
                                      <b>Document</b>
                                    </p>
                                  )}
                                  {!!hs.user_comment && (
                                    <p className="user-comment">
                                      "{hs.user_comment}"
                                    </p>
                                  )}
                                  <p> {hs.user_name}</p>
                                </div>
                              );
                            })}
                        {historicUserAction &&
                          historicUserAction.filter(
                            (hs) => hs.turbine_id === turbine.id
                          ).length === 0 && (
                            <div>
                              <p style={{ color: "darkred" }}>
                                No team reaction within the first 3 weeks.
                              </p>
                            </div>
                          )}
                      </div>
                    );
                  })}
            </div>
          )}
        </>
      )}
    </div>
  );
}